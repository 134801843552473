@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Newsreader:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Palatino+Linotype&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

:root {
	--colour-white: #ffffff;
	--colour-blue: #0044ff;
	--colour-blue-tint: #3c80ff;
	--colour-blue-two: #0066ff;
	--colour-blue-three: #0088ff;
	--colour-red: #cc0000;
	--colour-red-tint: #ff1e1e;
	--colour-red-two: #ee4400;
	--colour-green: #00ff44;
	--colour-light-green: #00ffaa;
	--navbar-width: 68px;
	--navbar-mobile-height: 58px;
}

.theme-dark,
.theme-dim {
	--colour-grey-one: #000000;
	--colour-grey-two: #18181e;
	--colour-grey-three: #2f2f3a;
	--colour-grey-four: #464656;
	--colour-grey-five: #5d5d72;
	--colour-grey-six: #74748e;
	--text-colour-primary: #dddddd;
	--text-colour-secondary: #bbbbbb;
	--text-colour-tertiary: #999999;
	--colour-scroll-bar-thumb-background: var(--colour-grey-six);
}

.theme-light {
	--colour-grey-one: #fbfbfe;
	--colour-grey-two: #e8e8ea;
	--colour-grey-three: #dbdbde;
	--colour-grey-four: #ceced2;
	--colour-grey-five: #c1c1c6;
	--colour-grey-six: #b4b4ba;
	--text-colour-primary: #000000;
	--text-colour-secondary: #888888;
	--text-colour-tertiary: #aaaaaa;
	--colour-scroll-bar-thumb-background: var(--colour-grey-six);
}

/* .theme-dark {
	--colour-grey-one: #000000;
	--colour-grey-two: #0c0c0c;
	--colour-grey-three: #1a1a1a;
	--colour-grey-four: #262626;
	--colour-grey-five: #383838;
	--colour-grey-six: #545454;
	--text-colour-primary: #eeeeee;
	--text-colour-secondary: #bbbbbb;
	--text-colour-tertiary: #999999;
	--colour-scroll-bar-thumb-background: var(--colour-grey-six);
} */

/* .theme-dim {
	--colour-grey-one: #0a0a0d;
	--colour-grey-two: #18181e;
	--colour-grey-three: #2f2f3a;
	--colour-grey-four: #464656;
	--colour-grey-five: #5d5d72;
	--colour-grey-six: #74748e;
	--text-colour-primary: #dddddd;
	--text-colour-secondary: #bbbbbb;
	--text-colour-tertiary: #999999;
	--colour-scroll-bar-thumb-background: var(--colour-grey-six);
} */

.font-size-xs {
	--font-size-p-s: 11px;
	--font-size-p-s-height: 14px;
	--font-size-p-m: 12px;
	--font-size-p-m-height: 15px;
	--font-size-p-l: 15px;
	--font-size-h1: 17px;
	--font-size-h2: 15px;
	--font-size-h3: 14px;
	--font-size-h4: 13px;
	--font-size-h5: 12px;
	--font-size-h6: 12px;
}

.font-size-s {
	--font-size-p-s: 13px;
	--font-size-p-s-height: 16px;
	--font-size-p-m: 14px;
	--font-size-p-m-height: 17px;
	--font-size-p-l: 16px;
	--font-size-h1: 18px;
	--font-size-h2: 15px;
	--font-size-h3: 14px;
	--font-size-h4: 13px;
	--font-size-h5: 12px;
	--font-size-h6: 12px;
}

.App,
.font-size-m {
	--font-size-p-s: 14px;
	--font-size-p-s-height: 17px;
	--font-size-p-m: 15px;
	--font-size-p-m-height: 19px;
	--font-size-p-l: 17px;
	--font-size-h1: 19px;
	--font-size-h2: 16px;
	--font-size-h3: 15px;
	--font-size-h4: 14px;
	--font-size-h5: 13px;
	--font-size-h6: 13px;
}

.font-size-l {
	--font-size-p-s: 15px;
	--font-size-p-s-height: 19px;
	--font-size-p-m: 16px;
	--font-size-p-m-height: 20px;
	--font-size-p-l: 18px;
	--font-size-h1: 21px;
	--font-size-h2: 17px;
	--font-size-h3: 16px;
	--font-size-h4: 15px;
	--font-size-h5: 14px;
	--font-size-h6: 14px;
}

html,
#root {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;

	width: 100vw;
	height: 100%;
	overflow: hidden;

	--font-family: "Inter", sans-serif;
	font-family: var(--font-family);
	font-size: var(--font-size-p-m);
	color: var(--text-colour-primary);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

input {
	font-family: var(--font-family);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button,
button:focus {
	font-family: inherit;
	outline: 0 !important;
}

img {
	user-select: none;
}

.App {
	position: relative;
	z-index: 1;

	width: 100vw;
	height: 100%;
	max-height: 100vh;
	overflow: hidden;

	background: var(--colour-grey-one);
	color: var(--text-colour-primary);
}

.page {
	position: fixed;
}

::-webkit-scrollbar {
	width: 6px;
	background: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--colour-scroll-bar-thumb-background);
}

::-webkit-scrollbar-corner {
	background: var(--colour-grey-one);
}

@keyframes fade-in {
	0% {
		opacity: 0;
		pointer-events: none;
		user-select: none;
	}
	100% {
		opacity: 1;
		pointer-events: all;
		user-select: all;
	}
}

@keyframes fade-in-1 {
	0% {
		opacity: 0;
		pointer-events: none;
		user-select: none;
	}
	100% {
		opacity: 1;
		pointer-events: all;
		user-select: all;
	}
}

@keyframes fade-in-2 {
	0% {
		opacity: 0;
		pointer-events: none;
		user-select: none;
	}
	100% {
		opacity: 1;
		pointer-events: all;
		user-select: all;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		pointer-events: none;
		user-select: none;
	}
}

@keyframes wiggle-very-small {
	0%,
	100% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(0.2deg);
	}
	75% {
		transform: rotate(-0.2deg);
	}
}

@keyframes wiggle-small {
	0%,
	100% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(0.4deg);
	}
	75% {
		transform: rotate(-0.4deg);
	}
}

@keyframes wiggle-medium {
	0%,
	100% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(0.7deg);
	}
	75% {
		transform: rotate(-0.7deg);
	}
}

@keyframes wiggle-large {
	0%,
	100% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(1deg);
	}
	75% {
		transform: rotate(-1deg);
	}
}

.lightbox-openable-image {
	cursor: pointer;
	transition: filter 0.2s;
}

.lightbox-openable-image:hover {
	filter: brightness(75%);
}

.loading-background {
	background: linear-gradient(90deg, var(--colour-grey-three), var(--colour-grey-five), var(--colour-grey-three));
	background-size: 400%;
	animation: loading-background 5s infinite linear;
	transition: background 0.1s;
	opacity: 0.4;
}

@keyframes loading-background {
	0% {
		background-position: 0%;
	}
	100% {
		background-position: 400%;
	}
}
