.locations-menu-container {
	position: absolute;
	z-index: 4;
	right: -400px;
	width: calc(400px + 8px + 44px);
	height: 100%;
	transition: 0.2s;
	pointer-events: none;
}

.locations-menu-container > * {
	pointer-events: all;
}

.locations-menu-container-is-displaying {
	right: 0px;
}

.locations-menu-container-selected-location {
	width: min(100%, 800px);
}

.locations-menu-buttons {
	display: flex;
	flex-direction: column;
	gap: 8px 0;
	position: absolute;
	left: 0;
	bottom: 8px;
}

.locations-menu-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border: 1px solid var(--colour-grey-four);
	border-radius: 100%;

	width: 22px;
	height: 22px;

	background: var(--colour-grey-one);
	font-size: 22px;
	cursor: pointer;
	transition: 0.2s;
}

.locations-menu-btn:hover {
	background: var(--colour-grey-four);
}

.locations-menu-container:not(.locations-menu-container-is-displaying) .locations-menu-toggle-hierarchy-visible-btn {
	font-size: 20px;
}

.locations-menu-speed-container {
	display: flex;
	flex-direction: column-reverse;
	gap: 8px;
}

.locations-menu-speed,
.locations-menu-speed:hover {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: -6px 0;
	width: 44px;
	height: 44px;
	font-size: 32px;
	background: transparent;
	cursor: pointer;
}

.locations-menu-speed {
	opacity: 0.344;
}

.locations-menu-speed-active {
	opacity: 1;
}

@media (max-width: 750px) {
	.locations-menu-container {
		left: calc(100vw - 8px - 40px);
		width: calc(100vw + 8px + 40px);
	}

	.locations-menu-container-is-displaying {
		left: 8px;
		width: 100vw;
	}

	.locations-menu-buttons {
		position: absolute;
		left: 0;
		bottom: 8px;
	}
}
