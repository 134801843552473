.locations-location-regions {
	padding-top: 8px;
}

.locations-location-regions-version-container {
	display: flex;
	align-items: center;
	gap: 4px;
	margin: 0 4px;
	font-size: 14px;
}

.locations-location-regions-items {
	display: inline-block;
	flex-flow: column wrap;
	padding-right: 12px;

	width: calc(100% - 12px);
	height: calc(var(--locationsSubpageHeight) - 32px - 8px - 8px - 12px);
	overflow-y: overlay;
	overflow-x: hidden;
}

.locations-location-regions-items-container.editable-container-is-editing .locations-location-regions-items {
	height: calc(var(--locationsSubpageHeight) - 32px - 8px - 8px - 35px - 8px - 12px);
}

.locations-location-regions-start-btn-container {
	display: flex;
	justify-content: right;
	gap: 8px;
	margin-top: 6px;
	margin-bottom: 6px;
}

.locations-location-regions-start-btn {
	border: 0;
	border-radius: 32px;
	padding: 6px 12px;
	background: var(--colour-accent);
	color: #fff;
	font-size: 13px;
	transition: 0.2s;
	cursor: pointer;
}

.locations-location-regions-start-btn:hover {
	background: var(--colour-accent-hover);
}

.locations-location-regions-start-btn-active {
	background: var(--colour-red);
}

.locations-location-regions-start-btn-active:hover {
	background: var(--colour-red-tint);
}

@media (max-width: 750px) {
	.locations-location-regions-items-container.editable-container-is-editing .locations-location-regions-items {
		height: calc(var(--locationsSubpageHeight) - 32px - 8px - 35px - 8px - 30px - 12px);
	}
}
