.locations-location-title-container {
	display: flex;
	gap: 0 2px;
	align-items: center;
	margin-top: -2px;
}

.locations-location-title-container,
.locations-location-title-close-btn .icon-btn {
	color: var(--text-colour-tertiary);
}

.locations-location-title-label {
	margin-top: 2px;
	font-size: 12px;
	font-weight: 600;
}

.locations-location-title-go-to-btn {
	margin: 0;
	margin-bottom: -1px;
	margin-left: auto;
	padding: 0;
	border: 0;
	background: transparent;
	color: var(--text-colour-tertiary);
	transition: 0.2s;
	cursor: pointer;
}

.locations-location-title-go-to-btn:hover,
.locations-location-title-go-to-btn:focus {
	color: var(--text-colour-primary);
}

.locations-location-title-close-btn {
	margin-left: 8px;
}

.locations-location-title-close-btn .icon-btn:hover {
	color: var(--text-colour-primary);
}

@media (max-width: 768px) {
	.locations-location-title-container {
		margin-top: 2px;
	}
}

@media (max-width: 364px) {
	.locations-location-title-label {
		display: none;
	}

	.locations-location-title-go-to-btn {
		margin-left: 0;
		margin-right: auto;
	}
}
