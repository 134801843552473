.locations-surface-map-container {
	position: fixed;
	z-index: 1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;

	width: 100%;
	height: 100%;

	touch-action: none;
}

.locations-surface-map-container-hidden {
	display: none;
	pointer-events: none;
	opacity: 0;
}

.locations-surface-map-loading-circle-container {
	position: fixed;
	z-index: 10;
	top: 0;
	left: calc(var(--navbar-width) - 1px);
	width: calc(100vw - var(--navbar-width) + 1px);
	height: var(--vh);
	background: #000;
}

.locations-surface-map-loading-circle-container:not(.locations-surface-map-loading-circle-container-loaded) .loading-circle-container {
	animation: fade-in 0s 1;
	animation-fill-mode: forwards;
	animation-delay: 0.05s;
	opacity: 0;
}

.locations-surface-map-loading-circle-container-loaded {
	animation: fade-out 0.5s 1;
	animation-fill-mode: forwards;
	animation-delay: 1s;
}

.locations-surface-map-image-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 2;

	width: 100vw;
	height: var(--vh);

	transform-origin: 0px 0px;
	transform: scale(3) translate(0px, 0px);
	object-fit: contain;
	user-select: none;
}

.locations-surface-map-image {
	display: flex;
	align-items: center;
	position: relative;
	min-width: 24%;
	max-width: 100%;
	max-height: calc(100vh - 120px);
	width: calc(100% - 240px);
	max-height: calc(var(--vh));
	width: calc(100%);
	cursor: grab;
	user-select: none;
	text-align: center;
}

.locations-surface-map-image img {
	object-fit: contain;
}

.locations-surface-map-image-is-pixelated img {
	image-rendering: pixelated;
}

.locations-surface-map-image-components-container {
	position: absolute;
	z-index: 2;
}

.locations-surface-map-image-components-container svg {
	margin-top: 2px;
	margin-left: -1.5px;
	min-width: 100%;
	min-height: 100%;
}

.locations-surface-map-image-components-container svg path {
	fill: var(--regionColour);
	stroke: var(--regionColour);
	transition: 0.2s;
	pointer-events: all;
	stroke-width: 8px;
	opacity: 0;
}

.locations-surface-map-image-components-container-is-selecting .locations-surface-map-image-component {
	cursor: pointer;
}

.locations-surface-map-image-components-container-is-selecting .locations-surface-map-image-component:hover {
	fill: var(--regionSelectingForColour) !important;
	stroke: var(--regionSelectingForColour) !important;
	opacity: 0.3;
}

.locations-surface-map-image-component-selected {
	opacity: 1 !important;
	fill: var(--regionSelectingForColour) !important;
	stroke: var(--regionSelectingForColour) !important;
	transition: 0.2s !important;
	animation: locations-surface-map-image-component-pulse 1.25s infinite ease-in-out;
}

.locations-surface-map-image-component-selected:hover {
	opacity: 0.45 !important;
}

@keyframes locations-surface-map-image-component-pulse {
	0% {
		opacity: 0.7;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.7;
	}
}

.locations-surface-map-image-component-in-region {
	fill: var(--regionColour) !important;
	stroke: var(--regionColour) !important;
	transition: 0.2s;
	animation: none !important;
}

.locations-surface-map-image-component-hovering-over {
	fill: var(--regionColourTint) !important;
	stroke: var(--regionColourTint) !important;
	opacity: 0.5 !important;
	cursor: pointer;
}

.locations-surface-map-image-region-names-container {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	user-select: none;
}

.locations-surface-map-image-region-names {
	position: relative;
}

.locations-surface-map-image-region-name {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-family: "Playfair Display", serif;
	font-weight: 600;
	letter-spacing: 2px;
}

.locations-surface-map-image-region-names-text {
	position: absolute;
	width: max-content;
	opacity: 0;
	user-select: none;
	pointer-events: none;
	font-size: 16px;
}

.locations-surface-map-image-region-names-text > div {
	width: fit-content;
}

.locations-surface-map-movement-boxes-container {
	--movementBoxSize: 40px;
	position: fixed;
	z-index: 2;
	top: 0;
	left: var(--navbar-width);
	width: calc(100vw - var(--navbar-width));
	height: var(--vh);
	pointer-events: none;
	user-select: none;
}

.locations-surface-map-movement-box {
	position: absolute;
	display: block;
	content: "";
	pointer-events: all;
	opacity: 0;
}

.locations-surface-map-movement-box:nth-child(1) {
	left: calc(3 * var(--movementBoxSize));
	width: calc(100% - 6 * var(--movementBoxSize));
	height: var(--movementBoxSize);
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(2) {
	right: var(--movementBoxSize);
	width: calc(2 * var(--movementBoxSize));
	height: var(--movementBoxSize);
	background: #faa4;
}

.locations-surface-map-movement-box:nth-child(3) {
	right: 0;
	width: var(--movementBoxSize);
	height: var(--movementBoxSize);
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(4) {
	top: var(--movementBoxSize);
	right: 0;
	width: var(--movementBoxSize);
	height: calc(2 * var(--movementBoxSize));
	background: #faa4;
}

.locations-surface-map-movement-box:nth-child(5) {
	top: calc(3 * var(--movementBoxSize));
	right: 0;
	width: var(--movementBoxSize);
	height: calc(100% - 6 * var(--movementBoxSize));
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(6) {
	right: 0;
	bottom: var(--movementBoxSize);
	width: var(--movementBoxSize);
	height: calc(2 * var(--movementBoxSize));
	background: #faa4;
}

.locations-surface-map-movement-box:nth-child(7) {
	right: 0;
	bottom: 0;
	width: var(--movementBoxSize);
	height: var(--movementBoxSize);
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(8) {
	right: var(--movementBoxSize);
	bottom: 0;
	width: calc(2 * var(--movementBoxSize));
	height: var(--movementBoxSize);
	background: #faa4;
}

.locations-surface-map-movement-box:nth-child(9) {
	left: calc(3 * var(--movementBoxSize));
	bottom: 0;
	width: calc(100% - 6 * var(--movementBoxSize));
	height: var(--movementBoxSize);
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(10) {
	left: var(--movementBoxSize);
	bottom: 0;
	width: calc(2 * var(--movementBoxSize));
	height: var(--movementBoxSize);
	background: #faa4;
}

.locations-surface-map-movement-box:nth-child(11) {
	left: 0;
	bottom: 0;
	width: var(--movementBoxSize);
	height: var(--movementBoxSize);
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(12) {
	left: 0;
	bottom: var(--movementBoxSize);
	width: var(--movementBoxSize);
	height: calc(2 * var(--movementBoxSize));
	background: #faa4;
}

.locations-surface-map-movement-box:nth-child(13) {
	top: calc(3 * var(--movementBoxSize));
	left: 0;
	width: var(--movementBoxSize);
	height: calc(100% - 6 * var(--movementBoxSize));
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(14) {
	top: var(--movementBoxSize);
	left: 0;
	width: var(--movementBoxSize);
	height: calc(2 * var(--movementBoxSize));
	background: #faa4;
}

.locations-surface-map-movement-box:nth-child(15) {
	top: 0;
	left: 0;
	width: var(--movementBoxSize);
	height: var(--movementBoxSize);
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(16) {
	top: 0;
	left: var(--movementBoxSize);
	width: calc(2 * var(--movementBoxSize));
	height: var(--movementBoxSize);
	background: #faa4;
}

.locations-surface-map-movement-box:nth-child(17) {
	top: var(--movementBoxSize);
	right: var(--movementBoxSize);
	width: calc(2 * var(--movementBoxSize));
	height: calc(2 * var(--movementBoxSize));
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(18) {
	bottom: var(--movementBoxSize);
	right: var(--movementBoxSize);
	width: calc(2 * var(--movementBoxSize));
	height: calc(2 * var(--movementBoxSize));
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(19) {
	bottom: var(--movementBoxSize);
	left: var(--movementBoxSize);
	width: calc(2 * var(--movementBoxSize));
	height: calc(2 * var(--movementBoxSize));
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(20) {
	top: var(--movementBoxSize);
	left: var(--movementBoxSize);
	width: calc(2 * var(--movementBoxSize));
	height: calc(2 * var(--movementBoxSize));
	background: #aaa2;
}

.locations-surface-map-movement-box:nth-child(21) {
	top: var(--movementBoxSize);
	left: calc(3 * var(--movementBoxSize));
	width: calc(100% - 6 * var(--movementBoxSize));
	height: var(--movementBoxSize);
	background: #faa4;
}

.locations-surface-map-movement-box:nth-child(22) {
	top: calc(3 * var(--movementBoxSize));
	right: var(--movementBoxSize);
	width: var(--movementBoxSize);
	height: calc(100% - 6 * var(--movementBoxSize));
	background: #faa4;
}

.locations-surface-map-movement-box:nth-child(23) {
	bottom: var(--movementBoxSize);
	left: calc(3 * var(--movementBoxSize));
	width: calc(100% - 6 * var(--movementBoxSize));
	height: var(--movementBoxSize);
	background: #faa4;
}

.locations-surface-map-movement-box:nth-child(24) {
	top: calc(3 * var(--movementBoxSize));
	left: var(--movementBoxSize);
	width: var(--movementBoxSize);
	height: calc(100% - 6 * var(--movementBoxSize));
	background: #faa4;
}

.locations-surface-map-drawing-point {
	--pointWidth: 6px;
	position: absolute;
	z-index: 4;
	display: block;
	content: "";
	border-radius: 100%;
	width: calc(var(--pointWidth) / min(6, var(--zoom)));
	height: calc(var(--pointWidth) / min(6, var(--zoom)));
	background: var(--colour-accent-hover);
}

.locations-surface-map-drawing-point-first-hovering {
	background: #fff;
}

.locations-surface-map-drawing-shape-container {
	z-index: 6;
}

.locations-surface-map-drawing-line {
	--rotation: 0deg;
	position: absolute;
	z-index: 3;
	display: block;
	content: "";
	width: 2px;
	height: 2px;
	transform-origin: top center;
	background: var(--colour-accent);
	opacity: 0.8;
	transform: translateY(1px) translateX(-1px) rotate(var(--rotation));
	scale: 0.5;
}

.locations-surface-map-image-components-path-new {
	opacity: 0.5;
}

.locations-surface-map-image-new-components-container {
	position: absolute;
	z-index: 5;
	pointer-events: none;
	user-select: none;
}

.locations-surface-map-image-new-components-container svg {
	margin-top: 2px;
	margin-left: -1.5px;
	min-width: 100%;
	min-height: 100%;
}

.locations-surface-map-image-new-components-container svg path {
	fill: var(--colour-accent);
	stroke: var(--colour-accent);
	transition: 0.2s;
	pointer-events: all;
	stroke-width: 8px;
	opacity: 0.5;
}

.locations-surface-map-image-component-is-drawn {
	stroke-width: 2px !important;
}

.locations-surface-map-image-component-delete {
	fill: var(--colour-red) !important;
}

.locations-surface-map-image-components-container svg path {
	--regionColour: #fff;
	opacity: 0;
}

.locations-surface-map-container-is-selecting-components .locations-surface-map-image-components-container svg path,
.locations-surface-map-container-is-deleting-components .locations-surface-map-image-components-container svg path {
	opacity: 0.4;
}

.locations-surface-map-container-is-selecting-components svg path {
	opacity: 0.4 !important;
}

.locations-surface-map-container-is-selecting-components svg path:hover {
	fill: var(--regionSelectingForColour) !important;
	stroke: var(--regionSelectingForColour) !important;
	opacity: 0.8;
}

.locations-surface-map-container-is-selecting-components .locations-surface-map-image-component-selected {
	opacity: 1 !important;
}

.locations-surface-map-container-is-deleting-components .locations-surface-map-image-display-components-svg-hover {
	fill: var(--colour-red) !important;
	stroke: var(--colour-red) !important;
	opacity: 0.8 !important;
}

.locations-surface-map-image-display-components-container {
	top: 0;
	left: 0;
	opacity: 1;
	pointer-events: none;
	user-select: none;
	transform: translateY(calc(1px * min(0, -1 * var(--min_y) * 1 / var(--zoom))));
}

.locations-surface-map-container-is-selecting-components .locations-surface-map-image-display-components-container {
	opacity: 0;
}

.locations-surface-map-image-display-components-container svg {
	position: absolute;
	top: -3px;
	left: 0;
	transition: 0.2s;
	pointer-events: none;
	user-select: none;
	opacity: 0.9;
}

.locations-surface-map-image-display-components-container svg path {
	pointer-events: none;
	user-select: none;
	opacity: 1;
}

.locations-surface-map-image-display-components-svg-hover {
	opacity: 0.6 !important;
}

.locations-surface-map-container-is-positioning-place .locations-surface-map-image {
	cursor: none;
}

.locations-surface-map-positioning-place-container {
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;

	min-width: 100%;
	min-height: 100%;

	pointer-events: none;
	user-select: none;
}

.locations-surface-map-positioning-place-dot {
	position: absolute;
	display: block;
	content: "";

	width: 1px;
	height: 1px;

	background: #fff;
	transform: rotate(45deg) scale(max(0.8, calc(1 / var(--zoom) * 5))) translate(2px, 1px);
	transform-origin: center center;
	opacity: 0.7;
}

.locations-surface-map-places-container {
	position: absolute;
	z-index: 8;
	top: 0;
	left: 0;

	min-width: 100%;
	min-height: 100%;

	pointer-events: none;
	user-select: none;

	transform: translateY(calc(1px * min(0, -1 * var(--min_y) * 1 / var(--zoom)) + 31px));
}

.locations-surface-map-place-container {
	position: absolute;
}

.locations-surface-map-place {
	position: absolute;
	top: -4px;
	left: 50%;
	transform: translateX(-50%);
}

.locations-surface-map-place-with-location,
.locations-surface-map-place-with-location .locations-surface-map-place-symbol,
.locations-surface-map-place-with-location .locations-surface-map-place-name {
	pointer-events: all;
}

.locations-surface-map-place-container-marker .locations-surface-map-place {
	top: -8px;
}

.locations-surface-map-place-symbol {
	margin: 0 auto;
	width: fit-content;
	height: 9px;
	font-size: 8px;
	text-align: center;
	opacity: 0.5;
}

.locations-surface-map-place-name {
	font-size: 4px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 2px;
	width: max-content;
	max-width: 50px;
}

.locations-surface-map-place-container-is-major .locations-surface-map-place {
	top: -6px;
}

.locations-surface-map-place-container-marker.locations-surface-map-place-container-is-major .locations-surface-map-place {
	top: -12px;
}

.locations-surface-map-place-container-is-major .locations-surface-map-place-symbol {
	height: 14px;
	font-size: 12px;
}

.locations-surface-map-place-container-is-major .locations-surface-map-place-name {
	font-size: 5px;
}

@media (max-width: 750px) {
	.locations-surface-map-loading-circle-container {
		top: 0;
		left: 0;
		width: 100vw;
		min-height: calc(var(--vh) - var(--navbar-mobile-height));
		height: calc(var(--vh) - var(--navbar-mobile-height));
	}

	.locations-surface-map-loading-circle-container .loading-circle-container {
		z-index: 3;
	}

	.locations-surface-map-loading-circle-background {
		position: fixed;
		z-index: 1;
		display: block;
		content: "";
		top: 0;
		left: 0;
		width: 100vw;
		height: var(--vh);
		background: #000;
	}

	.locations-surface-map-movement-box {
		display: none;
		pointer-events: none;
	}
}
