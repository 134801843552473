.characters {
	margin: 0 auto;
	padding: 0 24px;
	padding-bottom: 48px;
	max-width: 1900px;
}

.characters-content-container {
	display: flex;
	flex-wrap: wrap;
}

.characters-groups-content-container {
	width: 100%;
	max-width: 100%;
}

.characters-relationship-chart-content-container {
	margin-bottom: 24px;
	width: 100%;
	max-width: 100%;
}

.characters-character-types-content-container {
	margin-top: 12px;
	min-width: 100%;
	width: 100%;
	max-width: 100%;
}

@media (max-width: 750px) {
	.characters {
		padding: 0 8px;
	}
}

@media (max-width: 950px) {
	.characters-content-container {
		display: block;
	}

	.characters-character-types-content-container {
		margin-top: 0;
		min-height: 0;
	}
}
